import React from 'react'
import Main from './Main'

export default class App extends React.Component {
  render(){
    return(
        <Main/>
    )
  }
}
